export const formatDateWithoutTime = (inputDate) => {
    const dateObj = new Date(inputDate);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};
export const formatUTCDate = (date, format) => {
    const dateToFormat = date ? new Date(date) : new Date();
    const placeholders = {
        YYYY: String(dateToFormat.getUTCFullYear()),
        MM: String(dateToFormat.getUTCMonth() + 1).padStart(2, '0'),
        DD: String(dateToFormat.getUTCDate()).padStart(2, '0'),
        T: 'T',
        HH: String(dateToFormat.getUTCHours()).padStart(2, '0'),
        mm: String(dateToFormat.getUTCMinutes()).padStart(2, '0'),
        ss: String(dateToFormat.getUTCSeconds()).padStart(2, '0'),
    };
    return format.replace(/YYYY|MM|DD|T|HH|mm|ss/g, (match) => placeholders[match]);
};
